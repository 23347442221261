<template>
  <div
    class="profiling-mini__content"
    ref="profilingSurvey"
    :class="{ 'profiling-mini__content_hidden': hidden }"
  >
    <div class="profiling-mini__content-head" @click="$emit('toggle', !hidden)">
      <div
        class="profiling-mini__content-title"
        v-html="survey.questions[activeQuestion].title"
      ></div>
      <div class="profiling-mini__content-arrow">
        <div class="profiling-mini__content-arrow-icon">
          <svg
            width="19"
            height="11"
            viewBox="0 0 19 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 9.25L9.5 1.75L17 9.25"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="profiling-mini__content-body" v-if="!hidden">
      <div
        class="profiling-mini__survey-question"
        v-for="(question, q_ind) in survey.questions"
        v-show="activeQuestion === q_ind"
        :key="q_ind"
      >
        <div class="profiling-mini__question-answers">
          <div
            class="profiling-mini__question-checkboxes"
            v-if="question.choices.filter((el) => el.is_correct).length > 1"
          >
            <div
              class="profiling-mini__question-checkbox"
              v-for="(answer, a_ind) in question.choices"
              :key="a_ind"
            >
              <input
                type="checkbox"
                :id="`checkbox_${q_ind}_${a_ind}`"
                v-model="questionList[q_ind]"
                :value="answer.id"
              />
              <div class="profiling-mini__question-checkbox-container">
                <div class="profiling-mini__question-checkbox-correct">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0039 6L6.50391 11L4.00391 8.72727"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <label
                :for="`checkbox_${q_ind}_${a_ind}`"
                class="profiling-mini__question-label"
                v-html="answer.title"
              ></label>
            </div>
          </div>
          <div class="profiling-mini__question-radios" v-else>
            <div
              class="profiling-mini__question-radio"
              v-for="(answer, a_ind) in question.choices"
              :key="a_ind"
            >
              <input
                type="radio"
                :id="`radio_${q_ind}_${a_ind}`"
                v-model="questionList[q_ind][0]"
                :value="answer.id"
              />
              <div class="profiling-mini__question-radio-container">
                <div class="profiling-mini__question-radio-correct">
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="10" height="10" rx="5" fill="#830051" />
                  </svg>
                </div>
              </div>
              <label
                :for="`radio_${q_ind}_${a_ind}`"
                class="profiling-mini__question-label"
                v-html="answer.title"
              ></label>
            </div>
          </div>
        </div>
      </div>

      <div class="profiling-mini__buttons mt-4 mb-4">
        <div
          class="button button_pink d-inline-flex mx-auto"
          @click="nextQuestion()"
          :class="{
            button_disabled:
              !questionList[activeQuestion] ||
              !questionList[activeQuestion].filter((el) => el).length,
          }"
        >
          <span v-if="activeQuestion < questionList.length - 1"
            >Следующий вопрос</span
          >
          <span
            v-else
            :class="{
              button_disabled:
                !questionList[activeQuestion] ||
                !questionList[activeQuestion].filter((el) => el).length,
            }"
            >Отправить</span
          >
          <svg
            v-if="activeQuestion < questionList.length - 1"
            class="ml-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 4.5L16.5 12L9 19.5"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ProfilingModal",
  props: {
    survey: Object,
    hidden: Boolean,
  },
  data: () => ({
    showSurveyResults: false,
    questionList: [],
    activeQuestion: 0,
    surveyPercentage: 0,
    surveyResultId: null,
  }),
  computed: {},
  methods: {
    ...mapActions(["fetchEducationModules", "fetchEducationDetail"]),
    async nextQuestion() {
      const vm = this;
      if (this.activeQuestion < this.questionList.length - 1) {
        this.activeQuestion++;
      } else {
        await this.surveyAnswerSend();
        vm.$emit("close");
      }
    },
    surveyAnswerSend() {
      if (!this.survey.result) {
        return this.$axios({
          url: "/api/sso/profiling/",
          method: "POST",
          data: {
            answers: [...this.questionList]
              .filter((el) => el)
              .map((el, ind) => ({
                question: this.survey.questions[ind].id,
                choices: Array.isArray(el) ? el : [el],
                template_type: "Мини-список",
              })),
          },
        })
          .then((res) => {
            return true;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        return this.$axios({
          url: `/api/sso/profiling/${this.survey.result}/`,
          method: "PUT",
          data: {
            answers: [...this.questionList]
              .filter((el) => el)
              .map((el, ind) => ({
                question: this.survey.questions[ind].id,
                choices: Array.isArray(el) ? el : [el],
                template_type: "Мини-список",
              })),
          },
        })
          .then((res) => {
            return true;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    fillQuestionsList() {
      for (let question of this.survey.questions) {
        if (question.answer_type == "1") {
          this.questionList.push([null]);
        } else {
          this.questionList.push([]);
        }
      }
      // question.choices.filter((el) => el.is_correct).length > 1
    },
  },
  created() {
    this.fillQuestionsList();
  },
};
</script>

<style lang="scss" scoped>
.profiling-mini {
  &__content {
    position: fixed;
    bottom: 30px;
    right: 5%;
    width: 343px;
    border: 1px solid #ebefee;
    border-radius: 8px;
    background-color: #fff;
    z-index: 1000;
    @media screen and (max-width: 767px) {
      width: 90%;
      bottom: 10px;
      right: auto;
      left: 50%;
      transform: translateX(-50%);
    }
    &_hidden {
      & .profiling-mini__content-arrow-icon {
        transform: rotate(0);
      }

      & .profiling-mini__content-head {
        border-radius: 8px;
      }
    }
    &-head {
      border-radius: 8px 8px 0 0;
      background-color: #d0006f;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      overflow: hidden;
    }
    &-arrow {
      cursor: pointer;
      margin-left: auto;
      width: 35px;
      flex-shrink: 0;
      background-color: #830051;
      display: flex;
      justify-content: center;
      align-items: center;

      &-icon {
        transform: rotate(180deg);
      }
    }
    &-title {
      padding: 16px;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      color: #fff;

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &-questions-count {
      margin-left: auto;
      font-family: "Roboto Slab", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #1f1f1f;

      @media screen and (max-width: 767px) {
        margin: 8px auto 0;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
    }
  }

  &__question-title {
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 29px;
    color: #1f1f1f;
    margin-bottom: 32px;
  }

  &__question-checkbox {
    padding: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    border: 1px solid #ebefee;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      .profiling-mini {
        &__question-checkbox {
          &-correct {
            background-color: #830051;
          }
        }
      }
    }

    & input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      border: 0;
      padding: 0;
      margin: 0;
      z-index: 2;
      cursor: pointer;

      &:checked {
        & ~ .profiling-mini {
          &__question-checkbox-container {
            & > .profiling-mini {
              &__question-checkbox {
                &-correct {
                  background-color: #830051;

                  & > * {
                    opacity: 1;
                  }
                }
              }
            }
          }
          &__question-label {
            color: #830051;
          }
        }
      }
    }

    &-container {
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      margin-right: 8px;
    }

    &-correct {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #830051;
      border-radius: 4px;
      transition: 0.3s;
      background-color: transparent;
      width: 100%;
      height: 100%;

      & > * {
        opacity: 0;
        flex-shrink: 0;
      }
    }
  }

  &__question-radio {
    padding: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    border: 1px solid #ebefee;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      .profiling-mini {
        &__question-radio {
          &-correct {
            background-color: #fff;
          }
        }
      }
    }

    & input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      border: 0;
      padding: 0;
      margin: 0;
      z-index: 2;
      cursor: pointer;

      &:checked {
        & ~ .profiling-mini {
          &__question-radio-container {
            & > .profiling-mini {
              &__question-radio {
                &-correct {
                  background-color: #fff;
                  & > * {
                    opacity: 1;
                  }
                }
              }
            }
          }
          &__question-label {
            color: #830051;
          }
        }
      }
    }

    &-container {
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      margin-right: 8px;
    }

    &-correct {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #830051;
      border-radius: 50%;
      transition: 0.3s;
      background-color: transparent;
      width: 100%;
      height: 100%;

      & > * {
        opacity: 0;
        flex-shrink: 0;
      }
    }
  }

  &__question-label {
    margin: auto 0;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #3c4242;
    cursor: pointer;
  }

  &__survey-result {
    display: flex;
    flex-direction: column;
    height: 100%;

    .profiling-mini {
      &__question-checkbox {
        pointer-events: none;

        &_correct {
          & > .profiling-mini {
            &__question-checkbox-container {
              & > .profiling-mini {
                &__question-checkbox {
                  &-correct {
                    background-color: #830051;
                    & > * {
                      opacity: 1;
                    }
                  }
                }
              }
            }

            &__question-label {
              color: #830051;
            }
          }
        }

        &_incorrect {
          & > .profiling-mini {
            &__question-checkbox-container {
              & > .profiling-mini {
                &__question-checkbox {
                  &-correct {
                    background-color: #da338c;
                    border-color: #da338c;
                    & > * {
                      opacity: 1;
                    }
                  }
                }
              }
            }

            &__question-label {
              color: #ec99c5;
            }
          }
        }
      }

      &__question-radio {
        pointer-events: none;

        &_correct {
          & > .profiling-mini {
            &__question-radio-container {
              & > .profiling-mini {
                &__question-radio {
                  &-correct {
                    background-color: #fff;
                    & > * {
                      opacity: 1;
                    }
                  }
                }
              }
            }
            &__question-label {
              color: #830051;
            }
          }
        }

        &_incorrect {
          & > .profiling-mini {
            &__question-radio-container {
              & > .profiling-mini {
                &__question-radio {
                  &-correct {
                    background-color: #fff;
                    border-color: #f6cce2;
                    & > * {
                      opacity: 1;
                    }
                  }
                }
              }
            }
            &__question-label {
              color: #ec99c5;
            }
          }
        }
      }

      &__survey-question {
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-title {
      margin-bottom: 32px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 23px;
      font-weight: 400;
      line-height: 29px;
      text-align: center;
      color: #1f1f1f;

      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &-button {
      margin: 0 auto;
      width: fit-content;
      padding-left: 16px;
      padding-right: 16px;
      color: #fff;
      background-color: #6bc399;

      @media screen and (max-width: 767px) {
        width: 100%;
        max-width: 280px;
        margin: 0 auto;
        display: flex;
      }
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>